.editor-container {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    color: var(--foreground);
     margin-top: 65px; 
  }
  
  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .6rem 2rem;
    border-bottom: 1px solid var(--border);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--background);
    z-index: 1000;
  }
  
  .editor-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary);
  }
  
  .editor-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 80px;
    height: calc(100vh - 100px);
    overflow: hidden;
  }
  
  @media (min-width: 1024px) {
    .editor-content {
      flex-direction: row;
    }
  }
  
  .editor-main {
    flex-grow: 1;
    margin-right: 2rem;
    overflow-y: auto; /* Allow vertical scrolling if needed */
    display: flex;
    flex-direction: column;
  }
  
  .image-preview-container {
    flex-shrink: 0; /* Prevent the preview from shrinking */
  }
  
  .editor-controls {
    width: 300px;
    overflow-y: auto; /* Make controls scrollable */
    max-height: calc(100vh - 100px); /* Adjust based on your header height */
  }
  
  .editor-drop-area {
    width: fit-content;
    height: 500px;
    border: 2px dashed var(--border);
    display: flex;
    flex-direction: column;
    padding: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: var(--radius);
    transition: background-color 0.3s ease;
    background-image: linear-gradient(45deg, var(--card) 25%, transparent 25%),
      linear-gradient(-45deg, var(--card) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, var(--card) 75%),
      linear-gradient(-45deg, transparent 75%, var(--card) 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  }
  
  .editor-drop-area:hover {
    background-color: rgba(var(--accent-rgb), 0.1);
  }
  
  .editor-drop-area i {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--muted-foreground);
  }
  
  .editor-drop-area p {
    font-size: 1.2rem;
    color: var(--muted-foreground);
  }
  
  .editor-settings {
    position: relative;
  }
  
  .editor-settings-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--foreground);
    transition: color 0.3s ease;
  }
  
  .editor-settings-button:hover {
    color: var(--primary);
  }
  
  .editor-settings-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--card);
    border: 1px solid var(--border);
    border-radius: var(--radius);
    padding: 1rem;
    z-index: 10;
    width: 200px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .editor-settings-item {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--foreground);
    text-decoration: none;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .editor-settings-item:hover {
    background-color: var(--accent);
  }
  
  .editor-settings-item.user-name {
    cursor: default;
    font-weight: bold;
  }
  
  .editor-settings-item.user-name:hover {
    background-color: transparent;
  }
  
  .editor-sign-out-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: white;
    color: var(--background);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .editor-sign-out-button:hover {
    background-color: var(--muted-foreground);
  }
  
  .editor-sign-in-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--card);
    padding: 2rem;
    border-radius: var(--radius);
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .editor-popup-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary);
    color: var(--primary-foreground);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .editor-popup-button:hover {
    background-color: var(--accent);
  }
  
  .editor-close-button {
    padding: 0.5rem 1rem;
    background-color: var(--secondary);
    color: var(--secondary-foreground);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .editor-close-button:hover {
    background-color: var(--muted);
  }
  
  /* Styles for ImagePreview */
  .image-preview {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    overflow: hidden;
  }
  
  /* Styles for ImageCarousel */
  #carousel-container {
    margin-bottom: 10px;
  }
  
  #image-carousel {
    display: flex;
    overflow-x: auto;
    padding: 1rem 0;
  }
  
  #image-carousel img {
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.3s ease;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
  }
  
  #image-carousel img.active {
    border-color: var(--primary);
  }
  
  .navigation-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .navigation-button {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px;
  }
  
  .navigation-button-prev {
    border-radius: 0.25rem;
  }
  
  .navigation-button-next {
    border-radius: 0.25rem;
  }
  
  /* Styles for Controls */
  .control-group {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--card);
    border-radius: var(--radius);
  }
  
  .control-group h3 {
    margin-bottom: 0.5rem;
    color: var(--primary);
  }
  
  .control-item {
    margin-bottom: 0.5rem;
  }
  
  .control-item label {
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .control-item input[type="range"] {
    width: 100%;
  }
  
  .control-item input[type="color"] {
    width: 100%;
    height: 30px;
  }
  
  .control-button {
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .control-button:hover {
    background-color: var(--accent);
  }


  .navigation-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .navigation-button {
    width: 150px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--foreground);
    background-color: var(--secondary);
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px;
    border-radius: 0.25rem;
    font-size: 14px; /* Adjust as needed */
  }
  
  .navigation-button:hover {
    background-color: var(--accent);
  }
  
  .navigation-button i {
    margin-right: 5px;
    margin-left: 5px;
  }


  .editor-upgrade-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--card);
    padding: 2rem;
    border-radius: var(--radius);
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .editor-upgrade-popup h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary);
  }
  
  .editor-upgrade-popup p {
    margin-bottom: 1.5rem;
    color: var(--foreground);
  }
  
  .editor-upgrade-popup .editor-popup-button,
  .editor-upgrade-popup .editor-close-button {
    margin-right: 1rem;
  }




  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--card);
    padding: 2rem;
    border-radius: var(--radius);
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
  }
  
  .modal h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary);
  }
  
  .modal label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .modal input[type="text"],
  .modal input[type="checkbox"] {
    margin-right: 0.5rem;
    color: var(--accent);
  }
  
  .modal button {
    margin-top: 1rem;
    margin-right: 0.5rem;
  }



  .ad-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .ad-left,
  .ad-right {
    width: 160px; /* Adjust based on your ad size */
    min-height: 600px; /* Adjust based on your ad size */
  }
  
  .main-content {
    flex-grow: 1;
    max-width: calc(100% - 320px); /* Adjust based on your ad size */
  }
  
  /* Responsive layout */
  @media (max-width: 1200px) {
    .ad-wrapper {
      flex-direction: column;
    }
  
    .ad-left,
    .ad-right {
      width: 100%;
      min-height: 100px; /* Adjust for horizontal ad */
    }
  
    .main-content {
      max-width: 100%;
    }
  }


  .control-group {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--card);
    border-radius: var(--radius);
  }
  
  .control-group summary {
    cursor: pointer;
  }
  
  .control-group summary.premium {
    color: #fed830;
    display: flex;
    align-items: center;
  }
  
  .control-group summary.premium::after {
    content: 'Premium';
    font-size: 0.8em;
    background-color: #fed830;
    color: black;
    padding: 2px 5px;
    border-radius: 3px;
    margin-left: 10px;
  }
  
  .preset-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .preset-item button {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
  
  .preset-item .delete-preset-btn {
    flex-grow: 0;
    background-color: rgb(255, 72, 72);
    color: rgb(255, 225, 225);
    border: none;
    cursor: pointer;
    padding: 1rem 1rem;
  }
  
  
  .control-button i {
    margin-right: 0.5rem;
  }
  
  .control-button:hover {
    background-color: var(--accent);
  }
  
  .button-group {
    margin-bottom: 1rem;
  }


  /* Base mobile styles - Add these to your EditorContainer.css */
@media screen and (max-width: 768px) {
  .editor-container {
    margin-top: 60px; /* Reduced top margin for mobile */
  }

  .editor-header {
    padding: 0.5rem 1rem;
    height: 60px;
  }

  .editor-logo {
    font-size: 1.2rem;
  }

  .editor-content {
    flex-direction: column;
    padding: 0.5rem;
    margin-top: 0;
    height: auto;
    overflow: visible;
  }

  .editor-main {
    margin-right: 0;
    margin-bottom: 1rem;
    overflow: visible;
  }

  .image-preview-container {
    width: 100%;
    height: auto;
    max-height: 50vh;
  }

  .image-preview {
    height: auto;
    min-height: 200px;
    max-height: 50vh;
  }

  #image-preview {
    height: auto;
    max-height: 10vh;
    min-height: 350px;
    min-width: -webkit-fill-available;
  }


  .editor-controls {
    max-height: none;
    overflow: visible;
    padding-bottom: 60px;
    align-self: center;
  }

  .editor-drop-area {
    width: 100%;
    height: 300px;
    padding: 2rem;
  }

  .editor-drop-area i {
    font-size: 2rem;
  }

  .editor-drop-area p {
    font-size: 1rem;
  }

  /* Settings dropdown adjustments */
  .editor-settings-dropdown {
    right: 1rem;
    width: calc(100vw - 2rem);
    max-width: 300px;
  }

  /* Popup adjustments */
  .editor-sign-in-popup,
  .editor-upgrade-popup {
    width: 90%;
    max-width: 350px;
    padding: 1.5rem;
  }

  /* Carousel adjustments */
  #image-carousel {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    padding: 0.5rem 0;
  }

  #image-carousel::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .navigation-buttons-container {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  #root {
    align-self: center;
  }

  .navigation-button {
    width: calc(50% - 0.5rem);
    height: 36px;
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  /* Controls adjustments */
  .control-group {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .control-item {
    margin-bottom: 0.75rem;
  }

  /* Ad placements for mobile */
  .editor-content {
    margin: 0 !important; /* Override inline styles */
  }

  /* Move ads to top and bottom for mobile */
  [class*="adsbygoogle"] {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    transform: none !important;
    width: 100% !important;
    height: 100px !important;
    max-height: 100px !important;
    margin: 0.5rem 0;
  }
}

/* Smaller phones */
@media screen and (max-width: 480px) {
  .editor-header {
    padding: 0.5rem;
  }

  .editor-logo {
    font-size: 1rem;
  }

  .editor-settings-button {
    font-size: 1.2rem;
  }

  .editor-drop-area {
    height: 250px;
  }

  .navigation-button {
    width: 100%;
    margin: 0.25rem 0;
  }

  /* Adjust popup sizes */
  .editor-sign-in-popup,
  .editor-upgrade-popup {
    width: 95%;
    padding: 1rem;
  }

  .editor-popup-button,
  .editor-close-button {
    width: 100%;
    margin: 0.5rem 0;
  }
}

/* Handle landscape mode */
@media screen and (max-height: 480px) and (orientation: landscape) {
  .editor-content {
    flex-direction: row;
  }

  .editor-main {
    width: 60%;
    margin-right: 1rem;
  }

  .editor-controls {
    width: 40%;
  }

  .image-preview-container {
    max-height: 70vh;
  }

  /* Adjust popup positioning */
  .editor-sign-in-popup,
  .editor-upgrade-popup {
    max-height: 90vh;
    overflow-y: auto;
  }
}

/* Tablet adjustments */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .editor-content {
    flex-direction: column;
  }

  .editor-main {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .editor-controls {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  /* Adjust ad placements for tablets */
  [class*="adsbygoogle"] {
    position: relative !important;
    width: 728px !important;
    height: 90px !important;
    margin: 1rem auto;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

/* Fix for iOS Safari bottom bar */
@supports (-webkit-touch-callout: none) {
  .editor-content {
    padding-bottom: env(safe-area-inset-bottom, 20px);
  }

  .editor-controls {
    padding-bottom: calc(env(safe-area-inset-bottom, 20px) + 60px);
  }
}



