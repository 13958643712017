.sign-in-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
    color: var(--foreground);
    padding: 2rem;
  }
  
  .sign-in-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 1rem;
  }
  
  .sign-in-container p {
    font-size: 1.2rem;
    color: var(--muted-foreground);
    margin-bottom: 2rem;
  }
  
  .sign-in-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 300px;
  }
  
  .sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background-color: var(--card);
    color: var(--foreground);
    border: 1px solid var(--border);
    border-radius: var(--radius);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sign-in-button:hover {
    background-color: var(--accent);
  }
  
  .sign-in-button i {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  .sign-in-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .sign-in-info {
    margin-top: 2rem;
    font-size: 0.9rem;
    text-align: center;
    color: var(--muted-foreground);
  }
  
  .sign-in-info a {
    color: var(--primary);
    text-decoration: none;
  }
  
  .sign-in-info a:hover {
    text-decoration: underline;
  }