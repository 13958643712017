@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --background: #1a1a1a;
  --foreground: #ffffff;
  --card: #2a2a2a;
  --card-foreground: #ffffff;
  --primary: #ffffff;
  --primary-foreground: #1a1a1a;
  --secondary: #3a3a3a;
  --secondary-foreground: #ffffff;
  --muted: #4a4a4a;
  --muted-foreground: #d4d4d4;
  --accent: #5a5a5a;
  --accent-foreground: #ffffff;
  --border: #3a3a3a;
  --input: #3a3a3a;
  --ring: #ffffff;
  --radius: 1rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background);
  color: var(--foreground);
  line-height: 1.5;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--primary);
  font-weight: 600;
}

#editor-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}


#canvas-outline {
  position: absolute;
  border: 1px dashed rgba(255, 0, 0, 0.3);
  pointer-events: none;
  box-sizing: border-box;
  z-index: 10;
}

#image-preview p {
  text-align: center;
  color: var(--muted-foreground);
}


.control-group {
  margin-bottom: 1rem;
  background-color: var(--secondary);
  border-radius: calc(var(--radius) * 0.75);
  padding: 1rem;
  border: 0.5px solid var(--border);
}

.control-group summary {
  cursor: pointer;
  color: var(--primary);
  font-weight: 500;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--foreground);
  font-weight: 300;
  margin-top: 0.5rem;
}

input[type="range"] {
  width: 100%;
  background: var(--muted);
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  outline: none;
  margin: 10px 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--primary);
  cursor: pointer;
  border-radius: 50%;
}

input[type="color"] {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: calc(var(--radius) * 0.5);
  background-color: var(--secondary);
}

input[type="checkbox"] {
  margin-right: 0.5rem;
}

input[type="number"] {
  width: 70px;
  padding: 5px;
  background-color: var(--input);
  border: 1px solid var(--border);
  border-radius: calc(var(--radius) * 0.25);
  color: var(--foreground);
}

input[type="file"]#background-image {
  width: 100%;
  padding: 0.5rem;
  font-family: 'Poppins', sans-serif;
  background-color: var(--muted);
  color: var(--foreground);
  border: 1px solid var(--border);
  border-radius: calc(var(--radius) * 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="file"]#background-image:hover {
  background-color: var(--accent);
}

input[type="file"]#background-image::file-selector-button {
  padding: 0.5rem;
  background-color: var(--secondary);
  color: var(--secondary-foreground);
  border: none;
  border-radius: calc(var(--radius) * 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="file"]#background-image::file-selector-button:hover {
  background-color: var(--accent);
  color: var(--accent-foreground);
}

input[type="checkbox"]#transparent-bg {
  appearance: none;
  width: 1.08rem;
  height: 1.08rem;
  background-color: var(--muted);
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: relative;
}

input[type="checkbox"]#transparent-bg:checked {
  background-color: var(--accent);
  border-color: var(--accent);
}

input[type="checkbox"]#transparent-bg::after {
  content: '\2713';
  font-size: 1rem;
  color: var(--accent-foreground);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

input[type="checkbox"]#transparent-bg:checked::after {
  display: block;
}

button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  background-color: var(--muted);
  color: var(--primary);
  border: none;
  border-radius: calc(var(--radius) * 0.75);
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 200;
  width: 100%;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-family: 'Poppins';
}

button:hover {
  background-color: var(--accent);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

button i {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.checkerboard {
  background-image:
      linear-gradient(45deg, rgba(85, 85, 85, 0.2) 25%, transparent 25%),
      linear-gradient(-45deg, rgba(85, 85, 85, 0.2) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, rgba(85, 85, 85, 0.2) 75%),
      linear-gradient(-45deg, transparent 75%, rgba(85, 85, 85, 0.2) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.canvas-size-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link-toggle {
  background: none;
  border: none;
  color: var(--primary);
  cursor: pointer;
  font-size: 1.5rem;
}

#background-image-preview {
  width: 100%;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: calc(var(--radius) * 0.5);
}



#image-carousel {
  display: flex;
  flex-direction: row;
}

#image-carousel img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s;
}

#image-carousel img.active {
  border-color: var(--primary);
}

#navigation-buttons {
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#navigation-buttons button {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 1.5rem;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  min-width: 800px;
  padding: 2rem;
  background-color: var(--card);
  border-radius: var(--radius);
}

#editor-container {
  display: flex;
  gap: 2rem;
}

#image-preview {
  flex: 1;
  min-height: 300px;
  height: 60vh;
  border: 2px dashed var(--border);
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-width: 300px;
}

#controls {
  width: 300px;
}

#carousel-container {
  margin-top: 1rem;
  overflow-x: auto;
  white-space: nowrap;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card);
  border-radius: var(--radius);
}

#editor-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  #editor-container {
    flex-direction: row;
  }
}

#image-preview {
  width: 100%;
  height: 70vh;
  min-height: 400px;
  border: 2px dashed var(--border);
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-width: 700px;
}

#controls {
  width: 100%;
}

@media (min-width: 1024px) {
  #controls {
    width: 300px;
  }
}

#carousel-container {
  margin-top: 1rem;
  overflow-x: auto;
  white-space: nowrap;
}

/* Ensure the canvas within the image preview is responsive */
#image-preview canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}